import { Stores, StudioStore } from "core/bootstrap";
import { asPhone, getStoreCodeParam, navigateLink } from "lib/helpers";
import { GoogleMap } from "modules/google-maps";

import Arrow from "./icons/arrow.svg";
import Chevron from "./icons/chevron.svg";
import Close from "./icons/close.svg";
import Phone from "./icons/phone.svg";
import Pin from "./icons/pin.svg";

const createIconElement = (icon: string, label: string) => {
  const iconElement = document.createElement("span");
  iconElement.setAttribute("style", "display: flex; place-items: center;");
  iconElement.setAttribute("aria-label", label);
  iconElement.innerHTML = icon;

  return iconElement;
};

const createDialog = (dialogContent: Element, baseClass: string) => {
  const newDialog = document.createElement("dialog");
  newDialog.classList.add(`${baseClass}__dialog--container`);

  newDialog.appendChild(dialogContent);
  dialogContent.setAttribute("style", "display: flex");

  return newDialog;
};

export default function LandingPagesHeader(
  root: Element,
  { studioStore }: Stores,
) {
  const baseClass = "landing-pages-header-widget";

  const buttonsRoot = root.querySelector(`.${baseClass}__buttons`);

  const dropdownButton = root.querySelector(`.${baseClass}__dropdown-button`);

  const dialogContent = root.querySelector(`.${baseClass}__dialog--source`);

  const mapContainer = root.querySelector(`.${baseClass}__map-container`);

  const closeButton = root.querySelector(`.${baseClass}__close-button`);
  const addressContainer = root.querySelector(`.${baseClass}__address`);
  const phoneContainer = root.querySelector(`.${baseClass}__phone`);

  const infoButtonFirst = root.querySelector(
    `.${baseClass}__info-buttons--first`,
  );
  const infoButtonSecond = root.querySelector(
    `.${baseClass}__info-buttons--second`,
  );

  if (
    !buttonsRoot ||
    !dropdownButton ||
    !dialogContent ||
    !mapContainer ||
    !closeButton ||
    !addressContainer ||
    !phoneContainer ||
    !infoButtonFirst ||
    !infoButtonSecond
  ) {
    return;
  }

  const renderWidget = (state: StudioStore) => {
    const storeCodeSearchParam = getStoreCodeParam();

    const selectedStudio = state.studios.find(
      (studio) => studio.storeCode === storeCodeSearchParam,
    );

    if (!selectedStudio) {
      return;
    }

    /* dropdown button */
    const pinIconElement = createIconElement(Pin, "pin icon");
    const chevronIconElement = createIconElement(Chevron, "chevron icon");

    const textElement = document.createElement("span");
    textElement.innerHTML = selectedStudio.description;

    dropdownButton.textContent = "";

    dropdownButton.appendChild(pinIconElement);
    dropdownButton.appendChild(textElement);
    dropdownButton.appendChild(chevronIconElement);

    dropdownButton.setAttribute("role", "button");

    /* close button */
    closeButton.setAttribute("role", "button");
    closeButton.innerHTML = Close;
    closeButton.setAttribute("aria-label", "close");

    /* map */
    const googleMap = new GoogleMap(mapContainer as HTMLElement, {});
    googleMap.renderMarkers(studioStore.getState().studios);

    /* address */
    addressContainer.innerHTML = `<p style="margin:0px">${selectedStudio.address} ${selectedStudio.address2},</p>
      <p style="margin:0px">${selectedStudio.city}, ${selectedStudio.state} ${selectedStudio.zip},</p>
      <p style="margin:0px">United States.</p>`;

    phoneContainer.innerHTML = `Phone: +1 ${asPhone(selectedStudio.phone)}`;

    /* get directions button */
    const arrowElement = createIconElement(Arrow, "arrow icon");
    infoButtonFirst.appendChild(arrowElement);
    infoButtonFirst.setAttribute("role", "link");
    infoButtonFirst.setAttribute(
      "data-href",
      `geo:${selectedStudio.latitude},${selectedStudio.longitude}`,
    );
    infoButtonFirst.addEventListener("click", navigateLink);
    infoButtonFirst.addEventListener("keydown", navigateLink);

    /* contact studio button */
    const phoneElement = createIconElement(Phone, "phone icon");
    infoButtonSecond.appendChild(phoneElement);
    infoButtonSecond.setAttribute("role", "link");
    infoButtonSecond.setAttribute("data-href", `tel:${selectedStudio.phone}`);

    infoButtonSecond.addEventListener("click", navigateLink);
    infoButtonSecond.addEventListener("keydown", navigateLink);

    [infoButtonFirst, infoButtonSecond, closeButton, dropdownButton].forEach(
      (btn) => {
        btn.setAttribute("tabindex", "0");
      },
    );

    /* dialog and listeners */
    const dialog = createDialog(dialogContent, baseClass);

    closeButton.addEventListener("click", () => {
      dialog.close();
    });

    closeButton.addEventListener("keydown", (e) => {
      const event = e as KeyboardEvent;

      if (event.key !== "Enter" && event.key !== " ") {
        return;
      }

      dialog.close();
    });

    closeButton.setAttribute("tabindex", "0");

    dropdownButton.addEventListener("click", () => {
      dialog.show();

      setTimeout(() => {
        googleMap.setCenter({
          latitude: selectedStudio.latitude,
          longitude: selectedStudio.longitude,
        });
      }, 250);
    });

    dropdownButton.addEventListener("keydown", (e) => {
      const event = e as KeyboardEvent;

      if (event.key !== "Enter" && event.key !== " ") {
        return;
      }

      dialog.show();

      setTimeout(() => {
        googleMap.setCenter({
          latitude: selectedStudio.latitude,
          longitude: selectedStudio.longitude,
        });
      }, 250);
    });

    buttonsRoot.appendChild(dialog);
  };

  const studiosState = studioStore.getState();

  if (studiosState) {
    renderWidget(studiosState);
  }

  studioStore.subscribe(renderWidget);
}
