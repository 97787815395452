import { convertTag } from "lib/helpers";
import type { Studio } from "services/booking";

const iconRegular =
  "https://uploads-ssl.webflow.com/615f535b3d97e2228b7fbce6/6217e15f2803940a5f0b79a4_icon-marker.svg";
const iconPresales =
  "https://uploads-ssl.webflow.com/615f535b3d97e2228b7fbce6/6217e15ea825e36d7a9ab15e_icon-marker-presales.svg";

export class GoogleMap {
  dragListener?: google.maps.MapsEventListener;
  markers: google.maps.Marker[];
  map: google.maps.Map;
  options: {
    onDragEnd?: (coordinates: { latitude: number; longitude: number }) => void;
    dragToggle?: HTMLInputElement | null;
    renderTooltip?: (studio: Studio) => HTMLElement;
    onMarkerClick?: (coordinates: {
      latitude: number;
      longitude: number;
    }) => void;
    showMapButton?: HTMLButtonElement | null;
    mapAndControls?: HTMLElement | null;
  };

  constructor(container: HTMLElement, options: GoogleMap["options"]) {
    this.markers = [];
    this.map = new google.maps.Map(container, {
      mapId: "79e02768e65fd27b",
      zoom: 4.5,
      disableDefaultUI: true,
      zoomControl: true,
    });
    this.options = options;

    this.map.setOptions({ maxZoom: 11, minZoom: 4.5 });

    this.options.dragToggle?.addEventListener("change", (event) => {
      this.toggleDraggable((event.target as HTMLInputElement).checked);
    });

    if (this.options.onDragEnd) {
      this.toggleDraggable(true);
    }

    if (this.options.mapAndControls && this.options.showMapButton) {
      const mapAndControls = this.options.mapAndControls;

      this.options.showMapButton.addEventListener("click", () => {
        const pressed =
          this.options.showMapButton?.getAttribute("aria-pressed");
        if (pressed === "true") {
          this.options.showMapButton?.setAttribute("aria-pressed", "false");

          mapAndControls.style.display = "none";
        } else {
          this.options.showMapButton?.setAttribute("aria-pressed", "true");
          mapAndControls.style.display = "block";
        }
      });
    }
  }

  toggleDraggable(isDraggable: boolean) {
    if (isDraggable) {
      this.dragListener = this.map.addListener("dragend", () => {
        const coordinates = this.getCoordinatates();
        if (coordinates && this.options.onDragEnd) {
          this.options.onDragEnd(coordinates);
        }
      });
    } else {
      if (this.dragListener) {
        google.maps.event.removeListener(this.dragListener);
      }
    }
  }

  getCoordinatates() {
    const center = this.map.getCenter();
    if (center) {
      return {
        latitude: center.lat(),
        longitude: center.lng(),
      };
    }
  }

  renderMarkers(studios: Studio[]) {
    const infoWindow = new google.maps.InfoWindow();
    const bounds = new google.maps.LatLngBounds();

    this.markers = studios.map((location) => {
      const { latitude, longitude, description, isLive } = location;

      const marker = new google.maps.Marker({
        position: { lat: latitude, lng: longitude },
        icon: isLive ? iconRegular : iconPresales,
        map: this.map,
        title: description,
      });

      const position = marker.getPosition();

      if (position) {
        bounds.extend(position);
      }

      if (this.options.renderTooltip) {
        marker.addListener("click", () => {
          const contentElement = this.options.renderTooltip!(location);
          contentElement.setAttribute("class", "locations-map__info-window");
          convertTag(contentElement, "div");

          infoWindow.close();
          infoWindow.setContent(contentElement);
          infoWindow.open(marker.getMap(), marker);
        });
      }

      const onMarkerClick = this.options.onMarkerClick;

      if (onMarkerClick) {
        marker.addListener("click", () => {
          onMarkerClick({ latitude, longitude });
        });
      }

      return marker;
    });

    this.map.fitBounds(bounds);

    const center = bounds.getCenter();

    return {
      latitude: center.lat(),
      longitude: center.lng(),
    };
  }

  fitBounds(bounds: google.maps.LatLngBounds) {
    this.map.fitBounds(bounds);
  }

  setCenter({ latitude, longitude }: { latitude: number; longitude: number }) {
    this.map.setCenter({ lat: latitude, lng: longitude });
    this.map.setZoom(12);
  }
}
