const validServicesForLocalLinking = [
  "extract-fuse",
  "hydroglow-skin-brightening-facial",
  "hydropeptide-custom-facial",
  "keravive-scalp-treatment",
  "luminate-facial",
  "proteolytic-enzyme-facial",
  "resurfacing-peels",
  "retinol-plus-facial",
  "sonic-dermaplaning",
  "ultra-hydrating-facial",
  "vitamin-c-antioxidant-facial",
  "niagen-nr-iv-drips",
  "cryotherapy",
  "iv-drip-therapy",
  "nad-im-shot-therapy",
  "nad-iv-drip-therapy",
  "intramuscular-shots",
  "hydropeptide-oxygen-facial",
  "hydrafacial",
  "neveskin-shape",
  "compression",
  "infrared-sauna",
  "red-light-therapy",
  "neveskin-tone",
  "neveskin-facial",
  "niagen-nr-im-shots",
  "mild-hyperbaric-oxygen-therapy",
];

export default function ServiceList(root: HTMLElement) {
  const locationId =
    document.querySelector<HTMLDivElement>("[data-location-id]")?.dataset
      .locationId;

  if (locationId) {
    root
      .querySelectorAll<HTMLLinkElement>('[href*="/book-now"]')
      .forEach((link) => {
        const separator = link.href.includes("?") ? "&" : "?";
        link.href = `${link.href}${separator}location=${locationId}`;
      });
  }

  const locationSlug = document.querySelector<HTMLDivElement>(
    "[data-location-slug]",
  )?.dataset.locationSlug;

  if (locationSlug) {
    root
      .querySelectorAll<HTMLLinkElement>("[data-service-slug]")
      .forEach((link) => {
        if (link) {
          const serviceSlug = link.getAttribute("data-service-slug");
          //temp hardcode for now of only iv-drip-therapy
          if (
            serviceSlug &&
            validServicesForLocalLinking.includes(serviceSlug)
          ) {
            link.href = `/local/${serviceSlug}-${locationSlug}`;
          }
        }
      });
  }
}
