import { Stores, StudioStore } from "core/bootstrap";
import { getStoreCodeParam } from "lib/helpers";

const renderWidget = (root: Element) => (state: StudioStore) => {
  const baseClass = `landing-pages-footer`;
  const address = root.querySelector(`.${baseClass}__info--address`);

  const storeCodeSearchParam = getStoreCodeParam();

  const selectedStudio = state.studios.find(
    (studio) => studio.storeCode === storeCodeSearchParam,
  );

  if (!selectedStudio || !address) {
    return;
  }

  address.innerHTML = `<p style="margin:0px">${selectedStudio.address} ${selectedStudio.address2},</p>
    <p style="margin:0px">${selectedStudio.city}, ${selectedStudio.state} ${selectedStudio.zip}</p>`;
};

export default function LandingPagesFooter(
  root: Element,
  { studioStore }: Stores,
) {
  const studiosState = studioStore.getState();

  if (studiosState) {
    renderWidget(root)(studiosState);
  }

  studioStore.subscribe(renderWidget(root));
}
