import * as Sentry from "@sentry/browser";
import Booking from "components/Booking";
import CareCard from "components/CardCard";
import ContactForm from "components/ContactForm";
import CoopRegions from "components/CoopRegions";
import ElementsInteractivity from "components/ElementsInteractivity";
import Hours from "components/Hours";
import LandingPagesFooter from "components/LandingPagesFooter.ts";
import LandingPagesHeader from "components/LandingPagesHeader";
import Locations from "components/Locations";
import ServiceList from "components/ServiceList";
import SplideAwards from "components/SplideAwards";
import SplideBlogCategories from "components/SplideBlogCategories";
import SplideHomeServices from "components/SplideHomeServices";
import SplideLocationPromos from "components/SplideLocationPromos";
import SplideMobile from "components/SplideMobile";
import { startRestoreApp } from "core/app";
import { bootstrapComponents, StudioStore } from "core/bootstrap";
import Accordion from "modules/accordion";
import ConvertTags from "modules/convert-tags";
import CurrentYear from "modules/current-year";
import Dialog from "modules/dialog";
import Nav from "modules/nav";
import NavDropdowns from "modules/nav-dropdowns";
import { fetchStores, Studio } from "services/booking";
import { createStore } from "zustand/vanilla";

import { handleConsumerCampaignTracking } from "./lib/domain/handleConsumerCampaignTracking.ts";

if (process.env.SENTRY_DSN) {
  Sentry.init({
    release: `restore-website@${process.env.APP_VERSION}`,
    dsn: process.env.SENTRY_DSN,
    sampleRate: 0.05,
    environment: process.env.SENTRY_ENV ?? "local",
  });
}

const studioStore = createStore<StudioStore>((set) => ({
  studios: [],
  setStudios: (studios) => set({ studios }),
}));

void startRestoreApp(async () => {
  ConvertTags();
  CurrentYear();
  Dialog();
  Nav();
  NavDropdowns();
  Accordion();

  const cachedStores = sessionStorage.getItem("stores");

  if (cachedStores) {
    studioStore.getState().setStudios(JSON.parse(cachedStores) as Studio[]);
  } else {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    fetchStores()
      .then((stores) => {
        studioStore.getState().setStudios(stores);
        return stores;
      })
      .then((stores) =>
        sessionStorage.setItem("stores", JSON.stringify(stores)),
      );
  }

  const stores = {
    studioStore,
  };

  bootstrapComponents(
    {
      "care-card": CareCard,
      "contact-form": ContactForm,
      "splide-awards": SplideAwards,
      "splide-home-services": SplideHomeServices,
      "splide-blog-categories": SplideBlogCategories,
      "splide-location-promos": SplideLocationPromos,
      "splide-mobile": SplideMobile,
      "location-service-list": ServiceList,
      "elements-lottie": ElementsInteractivity,
      "mb-booking": Booking,
      hours: Hours,
      locations: Locations,
      "coop-regions": CoopRegions,
      "landing-pages-header": LandingPagesHeader,
      "landing-pages-footer": LandingPagesFooter,
    },
    stores,
  );

  await handleConsumerCampaignTracking();
});
